import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush=VueRouter.prototype.push
VueRouter.prototype.push=function push(location){
  return originalPush.call(this,location).catch(err=>err)
}



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/Home.vue'),
    redirect: '/homepage', // 访问home，重定向，显示主页
    children: [{
      path: '/homepage',
      name: 'homepage',
      component: () => import('../views/Home/HomePage.vue'),
      meta: {
        title: '主页'
      }
    },
    {
      path: '/sermanage',
      name: 'sermanage',
      component: () => import('../views/servicer/SerProd.vue'),
      meta: {
        title: '服务商管理'
      }
    },
    {
      path: '/unionmanage',
      name: 'unionmanage',
      component: () => import('../views/servicer/UnionManage.vue'),
      meta: {
        title: '运营商管理'
      }
    },
    {
      path: '/dev_qrcode',
      name: 'dev_qrcode',
      component: () => import('../views/servicer/DevQrcode.vue'),
      meta: {
        title: '设备二维码'
      }
    },
    {
      path: '/village',
      name: 'village',
      component: () => import('../views/media/Village.vue'),
      meta: {
        title: '小区信息'
      }
    },
    {
      path: '/road',
      name: 'road',
      component: () => import('../views/media/Road.vue'),
      meta: {
        title: '路段信息'
      }
    },
    {
      path: '/media_map',
      name: 'media_map',
      component: () => import('../views/media/MediaMap.vue'),
      meta: {
        title: '点位地图'
      }
    },
    {
      path: '/media',
      name: 'media',
      component: () => import('../views/media/Media.vue'),
      meta: {
        title: '点位资源'
      }
    },
    {
      path: '/media_type',
      name: 'media_type',
      component: () => import('../views/media/MediaType.vue'),
      meta: {
        title: '媒体类型'
      }
    },
    {
      path: '/applyorder',
      name: 'applyorder',
      component: () => import('../views/media/ApplyOrder.vue'),
      meta: {
        title: '点位申请'
      }
    },
    {
      path: '/advplan',
      name: 'advplan',
      component: () => import('../views/media/AdvPlanTime.vue'),
      meta: {
        title: '广告排期'
      }
    },
    {
      path: '/change',
      name: 'change',
      component: () => import('../views/media/ChangeMedia.vue'),
      meta: {
        title: '换刊换画'
      }
    },
    {
      path: '/publish',
      name: 'publish',
      component: () => import('../views/media/Publish.vue'),
      meta: {
        title: '上下刊'
      }
    },
    {
      path: '/fault_order',
      name: 'fault_order',
      component: () => import('../views/media/FaultOrder.vue'),
      meta: {
        title: '故障单'
      }
    },
    {
      path: '/repair_order',
      name: 'repair_order',
      component: () => import('../views/media/RepairOrder.vue'),
      meta: {
        title: '维修单'
      }
    },
    {
      path: '/advcst',
      name: 'advcst',
      component: () => import('../views/media/AdvCustomer.vue'),
      meta: {
        title: '客户资源'
      }
    },
    {
      path: '/mediatype',
      name: 'mediatype',
      component: () => import('../views/media/MediaType.vue'),
      meta: {
        title: '客户资源'
      }
    },
    {
      path: '/salespers',
      name: 'salespers',
      component: () => import('../views/media/SalesPerson.vue'),
      meta: {
        title: '销售人员'
      }
    },
    {
      path: '/gatemonitor',
      name: 'gatemonitor',
      component: () => import('../components/home/GateMonitor.vue'),
      meta: {
        title: '车道监控'
      }
    },
    {
      path: '/parkmanage',
      name: 'parkmanage',
      component: () => import('../views/servicer/ParkManage.vue'),
      meta: {
        title: '车场管理'
      }
    },
    {
      path: '/data_upload',
      name: 'data_upload',
      component: () => import('../views/servicer/DataUpload.vue'),
      meta: {
        title: '数据上报'
      }
    },
    {
      path: '/dev',
      name: 'dev',
      component: () => import('../views/park/Dev.vue'),
      meta: {
        title: '设备管理'
      }
    },
    {
      path: '/park',
      name: 'park',
      component: () => import('../views/park/Park.vue'),
      meta: {
        title: '车场设置'
      }
    },
    {
      path: '/post',
      name: 'post',
      component: () => import('../views/park/Post.vue'),
      meta: {
        title: '岗亭设置'
      }
    },
    {
      path: '/gate',
      name: 'gate',
      component: () => import('../views/park/Gate.vue'),
      meta: {
        title: '通道设置'
      }
    },
    {
      path: '/fee',
      name: 'fee',
      component: () => import('../views/park/Fee.vue'),
      meta: {
        title: '费率设置'
      }
    },
    {
      path: '/card',
      name: 'card',
      component: () => import('../views/emp/Card.vue'),
      meta: {
        title: '卡类设置'
      }
    },
    {
      path: '/carmanage',
      name: 'carmanage',
      component: () => import('../views/emp/CarManage.vue'),
      meta: {
        title: '车辆管理'
      }
    },
    {
      path: '/err_prone',
      name: 'err_prone',
      component: () => import('../views/emp/Errprone.vue'),
      meta: {
        title: '易错车牌'
      }
    },
    {
      path: '/blacklist',
      name: 'blacklist',
      component: () => import('../views/emp/Blacklist.vue'),
      meta: {
        title: '车辆管理'
      }
    },
    {
      path: '/discountrules',
      name: 'discountrules',
      component: () => import('../views/merchant/DiscountRules.vue'),
      meta: {
        title: '折扣设置'
      }
    },
    {
      path: '/merchant',
      name: 'merchant',
      component: () => import('../views/merchant/Merchant.vue'),
      meta: {
        title: '商家管理'
      }
    },

    {
      path: '/log_recharge',
      name: 'log_recharge',
      component: () => import('../views/merchant/LogRecharge.vue'),
      meta: {
        title: '商户充值明细'
      }
    },
    {
      path: '/shopdetail',
      name: 'shopdetail',
      component: () => import('../views/merchant/shop/ShopDetail.vue'),
      meta: {
        title: '商家明细'
      }
    },
    {
      path: '/fixcode',
      name: 'fixcode',
      component: () => import('../views/merchant/shop/FixCode.vue'),
      meta: {
        title: '固定二维码'
      }
    },
    {
      path: '/ticketdetail',
      name: 'ticketdetail',
      component: () => import('../views/merchant/shop/TicketDetail.vue'),
      meta: {
        title: '用券明细'
      }
    },
    {
      path: '/rechargedetail',
      name: 'rechargedetail',
      component: () => import('../views/merchant/LogRecharge.vue'),
      meta: {
        title: '充值记录'
      }
    },
    {
      path: '/log_enterpark',
      name: 'log_enterpark',
      component: () => import('../views/park/log/LogEnterPark.vue'),
      meta: {
        title: '在场记录'
      }
    },
    {
      path: '/log_exitpark',
      name: 'log_exitpark',
      component: () => import('../views/park/log/LogExitPark.vue'),
      meta: {
        title: '进出记录'
      }
    },
    {
      path: '/log_charge',
      name: 'log_charge',
      component: () => import('../views/park/log/LogCharge.vue'),
      meta: {
        title: '收费记录'
      }
    },
    {
      path: '/recovery_fee',
      name: 'recovery_fee',
      component: () => import('../views/park/log/RecoveryFee.vue'),
      meta: {
        title: '追缴逃费'
      }
    },
    {
      path: '/log_summary',
      name: 'log_summary',
      component: () => import('../views/park/log/LogSum.vue'),
      meta: {
        title: '统计汇总'
      }
    },
    {
      path: '/log_prepay',
      name: 'log_prepay',
      component: () => import('../views/park/log/LogPrepay.vue'),
      meta: {
        title: '场内预付记录'
      }
    },
    {
      path: '/gate_event',
      name: 'gate_event',
      component: () => import('../views/park/log/LogGateEvent.vue'),
      meta: {
        title: '道闸事件'
      }
    },
    {
      path: '/log_delay',
      name: 'log_delay',
      component: () => import('../views/park/log/LogDelay.vue'),
      meta: {
        title: '延期退费'
      }
    },
    {
      path: '/log_reserve',
      name: 'log_reserve',
      component: () => import('../views/park/log/LogReserve.vue'),
      meta: {
        title: '访客预约'
      }
    },
    {
      path: '/log_opt',
      name: 'log_opt',
      component: () => import('../views/park/log/LogOpt.vue'),
      meta: {
        title: '操作记录'
      }
    },
    {
      path: '/group',
      name: 'group',
      component: () => import('../views/park/Group.vue'),
      meta: {
        title: '组别管理'
      }
    },
    {
      path: '/park_user',
      name: 'park_user',
      component: () => import('../views/park/User.vue'),
      meta: {
        title: '用户管理'
      }
    },
    {
      path: '/sys',
      name: 'sys',
      component: () => import('../views/park/Sys.vue'),
      meta: {
        title: '系统设置'
      }
    }]
  },
  {
    path: '*',
    redirect: '/Login',
    meta: {
      title: '用户登录'
    }
  },
  {
    path: '/datascreen/:token',
    name: 'datascreen',
    component: () => import('../views/DataScreen.vue'),
  },
  {
    path: '/qrdiscount',
    name: 'qrdiscount',
    component: () => import('../views/merchant/shop/QrDiscount.vue'),
    meta: {
      title: '扫码减免'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由守卫，不是通过登录页面进入的，跳转到登录页面
router.beforeEach((to, from, next) => {
  // to 将要访问的页面
  // from 从哪个路径跳转过来的
  // next 放行

  document.title=to.meta.title
  // if (to.meta.title) {
  //   // 判断是否有标题
  //   console.log(to.meta.title)
  //   document.title = to.meta.title
  // } else {
  //   document.title = '停车场及广告点位综合管理平台'
  // }
  if (to.path === '/') return next()
  if(to.path==='/qrdiscount') return next()
  if(to.path.indexOf('datascreen')) return next()
  var session = window.sessionStorage.getItem('ca_login_token')
  if (!session) { return next('/') } else { next() }
})

export default router
